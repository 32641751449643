<template>
  <tablebox v-loading="loading"
    :element-loading-text="config.loading.text"
    :element-loading-spinner="config.loading.spinner"
    :element-loading-background="config.loading.background">
    <div class="dp-f bg-fff pt-20 pb-20 pr-20 pl-20">
      <tableLayout>
        <searchbox>
          <div class="input_box">
            <div class="input_title">菜单名称</div>
            <el-input class="input" v-model="state.searchKey.name" placeholder="请输入菜单名称" :maxlength="config.inputMaxlength" show-word-limit/>
          </div>
          <oabutton title="搜索" class="searcMR" @buttonclick="seach"></oabutton>
          <oabutton title="重置" CSStype=2 class="searcMR" @buttonclick="resetSeachData"></oabutton>
          <template v-slot:mr>
            <div class="dp-f">
              <oabutton title="新增" CSStype=2 class="searcMR" @buttonclick="unref(dialogRef).add()" v-permission="'system_menu:add'"></oabutton>
            </div>
          </template>
        </searchbox>
        <el-table :data="state.Notice" class="w100 mb-20"  :header-cell-style="{background:'var(--active-alpha)',color:config.table.color,fontWeight:config.table.fontWeight,fontSize:config.table.fontSize}" row-key="id">
          <el-table-column prop="name" label="菜单名称" />
          <el-table-column :align="'center'" prop="icon" label="默认图标">
            <template #default="scope">
              <span :class="['icon-'+scope.row.icon]" class="iconfont" v-if="scope.row.icon"></span>
            </template>
          </el-table-column>
          <el-table-column :align="'center'" prop="iconAc" label="选中图标">
            <template #default="scope">
              <span :class="['icon-'+scope.row.iconAc]" class="iconfont" v-if="scope.row.iconAc"></span>
            </template>
          </el-table-column>
          <el-table-column :align="'center'" prop="sort" label="排序" />
          <el-table-column :align="'center'" prop="path" label="组件路径" />
          <el-table-column :align="'center'" label="类型">
            <template #default="scope">
              {{getmenuType(scope.row.type)}}
            </template>
          </el-table-column>
          <el-table-column :align="'center'" prop="permission" label="权限标识" />
          <el-table-column :align="'center'" label="操作">
            <template #default="scope">
              <div class="dp-fc">
                <div class="Editor_span searcMR" @click="editclick(scope.row)" v-permission="'system_menu:edit'">修改</div>
                <div class="Delete_span" @click="deteleClick(scope.row)" v-permission="'system_menu:del'">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </tableLayout>
    </div>
    <!-- 用户修改编辑弹框 -->
    <Dialog ref="dialogRef" @Emit="getdatalb(state.datasearchjson)"></Dialog>
  </tablebox>
</template>
<script setup>
  import Dialog from "./menu/dialog"
  import { onMounted,reactive,ref,unref,nextTick } from 'vue'
  import {checkisnull} from "@/utils/server/check";
  import {objdata_ret,objdata_set} from "@/utils/server/format";
  import {handleCofirm,handleMessage} from "@/utils/server/confirm";
  import {httpToken} from "@/utils/request";
  import {getmenuType} from "@/utils/server/getcode";
  import qs from "qs";
  const dialogRef= ref(null);//修改编辑弹框ref
  const loading = ref(false);//loading 显示
  const state = reactive({
    Notice : [],//表格数据
    datasearchjson:[],//当前缓存构造器
    // selectdata_state:getXialaListdata2(['启用','禁用'],[0,1]),//状态下拉数据
    searchKey:{
      name:'',//用户名
    },//搜索框绑定值
  })
  // 编辑点击
  const editclick=(row)=>{
    unref(dialogRef).editclick(row)
  }
  let seach=(()=>{
    let searchjson = search_return();
    getdatalb(searchjson)
  })
  // 重置查询数据
  let resetSeachData=(()=>{
    objdata_ret(state.searchKey)
  })
  // 删除点击
  let deteleClick=((row)=>{
    handleCofirm().then(()=>{
      httpToken({
        method: "post",
        url: '/admin/sys-menu/del',
        data:qs.stringify({
          ids:row.menuId
        })
      }).then(()=>{
        getdatalb(state.datasearchjson)
      })
    }).catch(()=>{
      handleMessage('已取消删除','info')
    })
  })
  let search_return=(()=> {
    let jsons = [];
    if (!checkisnull(state.searchKey.name)) {
      jsons.push({ column: "name", type: "like", value: state.searchKey.name });
    }
    return jsons;
  })
   // 列表调取 页数 传入构造器
  let getdatalb=((searchjson=[])=> {
    loading.value=true
    let json = [
    ];
    for (let i = 0; i < searchjson.length; i++) {
      json.push(searchjson[i]);
    }
    state.datasearchjson=searchjson;
    httpToken({
      method: "post",
      url: '/admin/sys-menu/tree',
      data: qs.stringify({
        paramData:JSON.stringify(json)
      }),
    }).then((res) => {
      state.Notice=res.data
      loading.value=false;
    }).catch(()=>{
      loading.value=false;
    })
  })
  onMounted(() => {
    getdatalb()
  })
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss";  //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置

</style>